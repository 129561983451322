.app-container {
  display: flex;
  flex-direction: row;
  height: 92vh;
  width: 100%;
  margin: auto 0;
}

.panel-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 85%;
}

.header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
}

.text-center {
  text-align: center;
}

.label {
  color: #D8D8D8;
}

.tos-privacy-wrapper {
  padding: 0 10%;
}

input::placeholder {
  font-family: 'soleil-regular';
}

textarea::placeholder {
  font-family: 'soleil-regular';
}