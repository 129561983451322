.SingleDatePicker {
  display: flex;
}

.SingleDatePicker:hover {}

.SingleDatePickerInput_calendarIcon {
  margin: 0;
  padding: 0 14px 0 0;
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: #A5A5A5;
}

.DateInput_input {
  padding: 0 0 0 0;
  border: 0;
  font-family: 'soleil-book';
  font-size: 18px;
  text-transform: lowercase;
  color: #A5A5A5;
  transition: all 0.25s ease;
}

.DateInput_input:hover {
  cursor: pointer;
  color: #000000;
}

.DateInput_input::placeholder {
  color: #A5A5A5;
}

.SingleDatePicker_picker {
  display: block;
  right: 40px;
}

.CalendarDay__selected {
  background: #FF746C;
  border: 1px double #FF746C;
}

.CalendarDay__selected:hover {
  background: #FF746C;
  border: 1px double #FF746C;
}
