.automation-modal {
  border: 1.2px solid #A5A5A4;
  box-shadow: 0px 4px 0px #EAEAEA;
  margin-top: 5%;
  min-width: 814px;
  position: relative;
}

.automation-container {
  display: flex;
  height: 100%;
  padding: 65px 48px;
  width: 100%;
}

.close-modal-icon {
  padding: 8px 32px 0 0;
  position: absolute;
  right: 0;
}

.automation-details {
  border-right: 4px solid #F2F2F2;
  margin-right: 70px;
  padding-right: 35px;
  max-width: 220px;
  width: 30%;
}

.automation-header {
  font-size: 32px;
  font-weight: 600;
}

.automation-details-text-group {
  font-size: 14px;
  margin-top: 28px;
}

.s-label .page-label {
  color: #EE7C71;
  font-size: 12px;
}

.automation-inputs {
  width: 70%;
}

.automation-data-label {
  font-size: 14px;
  margin-bottom: 12px;
  font-family: 'soleil-semibold';
}

.pursue-contacts-dropdown {
  margin-bottom: 12px;
  max-width: 500px !important;
  width: 80% !important;
}

.pursue-contacts-dropdown > input {
  font-family: Soleil;
}

.pursue-contacts-dropdown b {
  font-family: 'soleil-semibold';
  font-weight: 300;
}


.pursue-recipients {
  display: flex;
  width: 100%;
}

.pursue-recipients-input {
  width: 100%;
}

.pursue-card-header-left {
  display: flex;
}

.submit-pursue-button {
  background-color: #E6675B;
  border-radius: 6px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  font-family: Soleil;
  height: 48px;
  width: 145px;
}

.pursue-card-container {
  border: 1.2px solid #A5A5A4;
  box-shadow: 0px 4px 0px #EAEAEA;
  height: 225px;
  margin-left: 50px;
  min-width: 500px;
  padding: 18px 25px 0 35px;
}

.pursue-card-wrapper {
  border-left: 4px solid #F2F2F2;
  padding-bottom: 24px;
  position: relative;

}

.pursue-card-wrapper:first-child {
  border-image: linear-gradient( #FFF 50%, #F2F2F2 50%) 1;
}

.pursue-card-wrapper:last-child {
  border-image: linear-gradient( #F2F2F2 50%, #FFF 50%) 1;
}

.pursue-card-wrapper::before {
  background: #F2F2F2;
  border-radius: 50%;
  content: '';
  height: 20px;
  left: -12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}

.pursue-card-header {
  display: flex;
  width: 100%;
}

.pursue-card-header-left {
  display: flex;
  align-items: center;
  width: 100%;
}

.pursue-card-header-text {
  font-family: 'soleil-semibold';
  font-size: 14px;
  margin-right: 8px;
}

.card-icons {
  color: #A5A5A4;
  font-size: 1.5em !important;
  cursor: pointer;
}

.pursue-card-divider {
  border: 2px solid #F2F2F2 !important;
}

.pursue-subject input {
  border: 0;
  font-family: Soleil;
  font-size: 14px;
  margin: 8px 0;
  width: 100%;
}

.pursue-card-label {
  color: #AAAAAA;
  font-size: 9px;
  font-family: Soleil;
}

.pursue-textarea textarea {
  border: 0;
  font-size: 12px;
  font-family: Soleil;
  height: 100%;
  margin-top: 8px;
  resize: none;
  width: 100%;
}

.pursue-add-message {
  align-items: center;
  border: 1.2px dashed #AAAAAA;
  color: #AAAAAA;
  display: flex;
  height: 120px;
  justify-content: center;
  margin-left: 50px;
  min-width: 500px;
}

.invalid-property {
  border: 2px solid red;
}

.invalid-property-contacts {
  border: 2px solid red;
  border-radius: 4px;
}

.invalid-property-contacts > * {
  outline: none !important;
}