.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  line-height: normal;
  align-items: center;
  font-size: 16px;
  min-width: 170px;
  width: 15%;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  user-select: none;
}

.logo {
  width: 100%;
  max-width: 120px;
  transition: all 0.25s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.logo:active {
  transform: scale(1);
}

.sidebar-nav-options {
  width: 100%;
  max-width: 120px;
  padding: 20px 0 0 0;
  list-style-type: none;
}

.sidebar-nav-options > li  {
  padding: 14px 0;
}

.sidebar-nav-options > li > a {
  font-family: 'soleil-book';
  font-size: 18px;
  color: #A5A5A5;
  text-decoration: none;
}

.sidebar-nav-options > li > a:hover {
  color: #000000;
}

li.selected-option > a {
  color: #000000;
}
