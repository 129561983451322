.modal-subroot {
  height: 100%;
  width: 100%;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.modal-dimmer {
  background-color: #FFFFFF;
  height: 100%;
  position: absolute;
  opacity: .8;
  width: 100%;
  z-index: 1000;
}

.modal-el {
  background: #FFFFFF;
  border: none;
  height: 100%;
  margin: 0 10%;
  width: 100%;
  z-index: 1001;
}