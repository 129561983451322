.legal-footer-wrapper {
  display: flex;
}

.legal-link {
  color: #E6675B;
}

.legal-link:hover {
  cursor: pointer;
}

.legal-separator {
  margin: 0 10px 0 10px;
}