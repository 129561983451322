/*
**
*** Servant Stylesheet
**
*/

/*
* Fonts
*/

@font-face {
  font-family: 'soleil-light';
  src: url('./assets/fonts/soleil-light.woff2') format('woff2'),
       url('./assets/fonts/soleil-light.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'soleil-regular';
  src: url('./assets/fonts/soleil-regular.woff2') format('woff2'),
       url('./assets/fonts/soleil-regular.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'soleil-regular-italic';
  src: url('./assets/fonts/soleil-regular-italic.woff2') format('woff2'),
       url('./assets/fonts/soleil-regular-italic.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'soleil-book';
  src: url('./assets/fonts/soleil-book.woff2') format('woff2'),
       url('./assets/fonts/soleil-book.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'soleil-semibold';
  src: url('./assets/fonts/soleil-semibold.woff2') format('woff2'),
       url('./assets/fonts/soleil-semibold.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'soleil-bold';
  src: url('./assets/fonts/soleil-bold.woff2') format('woff2'),
       url('./assets/fonts/soleil-bold.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'soleil-extrabold';
  src: url('./assets/fonts/soleil-extrabold.woff2') format('woff2'),
       url('./assets/fonts/soleil-extrabold.woff') format('woff');
  font-style: normal;
}

/*
* Global Styles
*/

* {
  font-family: 'soleil-regular', 'Helvetica Nueue', sans-serif;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  display: flex;
  box-sizing: border-box;
  font-family: 'soleil-regular', 'Helvetica Nueue', sans-serif;
  height: 100%;
  width: 100%;
}

body,
#root {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: #FFFFFF;
  line-height: normal;
  z-index: 10;
}

#modal-root {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

h1, h2, h3, h4, h5, h6, p, a {
  font-family: 'soleil-regular', 'Helvetica Nueue', sans-serif;
}

b {
  font-family: 'soleil-book'
}

i {
  font-family: 'soleil-italic'
}

/* Fix for Safari */
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

/**
 * Servant Styles
 */

.s-divider {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 3px;
  margin: auto 0;
  background: #F6F6F6;
}

a,
.s-link-grey {
  text-decoration: none;
  color: #A5A5A5;
  transition: all 0.3s ease;
  font-family: 'soleil-semibold'
}

a:hover,
.s-link-grey:hover {
  cursor: pointer;
  color: #000000;
}

.s-link,
.s-link-red {
  color: #FF746C;
  font-family: 'soleil-regular';
}

.s-link:hover,
.s-link-red:hover {
  color: #FF746C;
  cursor: pointer;
}

.s-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  user-select: none;
  font-family: 'soleil-book';
  font-size: 14px;
  color: #A5A5A5;
  margin-bottom: 1.3em;
  transition: all 0.25s ease;
}

/*
* Layout
*/

.s-container {
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.s-section {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-bottom: 40px;
  font-size: 18px;
}

.s-centered {
  text-align: center;
  align-items: center;
}

.s-box {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 3px 0px 0 rgba(0,0,0,0.06);
  padding: 2.5rem;
}

/*
* Error Box
*/

.s-command-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 3px 0px 0 rgba(0,0,0,0.06);
  padding: 5em 0 6.5em 0;
}

.s-command-error  p {
  font-size: 20px;
  margin-bottom: 40px;
}

.s-command-error .s-btn {
  width: auto;
}

/*
* Button
*/

.s-btn {
  align-items: center;
  display: flex;
  flex: 0 0;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 1em 4em 0.8em 4em;
  margin: 0;
  font-family: 'soleil-book';
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 3px solid #C4534D;
  border-radius: 3px;
  background-color: #FF746C;
  font-size: 18px;
  color: #FFFFFF;
  user-select: none;
  transition: all 0.1s ease;
}

.s-btn:hover {
  cursor: pointer;
}

.s-btn:active {
  margin-top: 1px;
  border-bottom: 2px solid transparent;
  position: relative;
}

.s-btn:focus {
  outline:0;
}

.s-btn-sm {
  font-size: 16px!important;
  padding: 0.2em 2.5em 0 2.5em;
  height: 50px;
}

/*
* Input
*/

.s-input-wrapper:hover {}

.s-input::placeholder {
  color: #DDDDDD;
}

.s-input {
  border: none;
  font-size: 18px;
  padding: 0.25em;
  resize: vertical;
  width: 100%;
  height: auto;
  min-height: 20px;
  color: #000000;
  text-align: left;
  font-family: 'soleil-regular';
}

.s-input:hover {
  cursor: pointer;
}

.s-input:focus {
  outline: none;
}

/*
* Textarea
*/

.s-textarea-wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 0 0;
}

.s-textarea-wrapper:hover {
  cursor: pointer;
}

.s-textarea-input {
  display: block;
  font-family: 'soleil-book';
  box-sizing: border-box;
  width: 100%;
  height: auto;
  /* min-height: 60px; */
  /* padding: 0; */
  /* border: none; */
  /* font-size: 18px; */
  /* line-height: 24px; */
  resize: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.s-textarea-input:empty:before {
  content: attr(placeholder);
  display: block;
  color: #A5A5A5;
  font-family: 'soleil-book';
}

.s-textarea-input div {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.s-textarea-input b {
  font-family: 'soleil-bold';
}

.s-textarea-input a {
  font-family: 'soleil-regular';
  text-decoration: underline;
  color: #000;
}

.s-textarea:hover {
  cursor: pointer;
}

.s-textarea:focus {
  outline: none;
}

.s-textarea:empty:before{
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: #EAEAEA;
}

.s-checkbox,
.s-checkbox input,
.s-checkbox label:before,
.s-checkbox label:after {
  height: 28px!important;
  width: 28px!important;
  font-size: 16px!important;
}

.s-checkbox .box:before,
.s-checkbox label:before {
  background: #FFFFFF!important;
  border-radius: 1px!important;
  border-color: rgba(0,0,0,0.3)!important;
  transition: all 0.25s ease!important;
  box-shadow: 0 2px 0px 0 rgba(0,0,0,0.08)!important;
}

.s-checkbox:hover .box:before,
.s-checkbox:hover label:before {
  border-color: rgba(0,0,0,0.5)!important;
  transform: scale(1.14);
}

.s-checkbox label:after {
  padding: 6px 0 0 2px!important;
}

/*
* Dropdown
*/

.s-dropdown {
  border: none!important;
  box-shadow: none!important;
  user-select: none!important;
  padding-left: 0!important;
}

.s-dropdown .menu {
  border: none!important;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1)!important;
  user-select: none!important;
  border-radius: 4px!important;
}

.ui.selection.dropdown .menu {
  border: 1px solid rgba(0,0,0,0.2)!important;
  border-top-width: 1px!important;
}

.s-dropdown .selected {
  background: #F6F6F6;
}

.s-dropdown:hover {
  cursor: pointer;
}

.s-dropdown option {}

.s-dropdown:focus {
  outline: none;
}

/*
* Select
*/

.s-select-wrapper {}

.s-select {}

.s-select:hover {
  cursor: pointer;
}

.s-select option {}

.s-input:focus {
  outline: none;
}

/*
* Animations
*/

@-webkit-keyframes pulseScale {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.015, 1.015, 1.015);
    transform: scale3d(1.015, 1.015, 1.015);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulseScale {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.015, 1.015, 1.015);
    transform: scale3d(1.015, 1.015, 1.015);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes pulseSlide {
  0% {
    right: 0px;
  }
  50% {
    right: 6px;
  }
  100% {
    right: 0px;
  }
}

@keyframes pulseSlide {
  0% {
    right: 0px;
  }
  50% {
    right: 6px;
  }
  100% {
    right: 0px;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes flicker {
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}

@-webkit-keyframes flicker {
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}
