.task-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 16px 5% 0 5%;
  width: 55%;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.task-details-header-container {
  display: flex;
}

.task-details-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 75%;
  border: none;
  font-family: 'soleil-semibold';
  font-size: 26px;
  margin: 16px 0;
}

.winston-container {
  width: 25%;
}

.task-details-header::placeholder {
  color: #A5A5A5;
}

.task-details-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 40px 0 0 0;
}

.task-details-content-left {
  display: flex;
  flex-direction: column;
  width: 67%;
  height: 100%;
}

.task-details-subtask-wrapper {
  margin-bottom: 24px;
}

.task-details-subheader {
  font-family: soleil-semibold;
  font-size: 16px;
  margin-bottom: 8px;
}

.subtask-list-el:last-child {
  margin-bottom: 8px;
}

.add-subtask-input {
  cursor: text;
  border-radius: 2px;
  border: 1px solid #CACBCD;
  font-family: 'soleil-book';
  font-size: 14px;
  line-height: 14px;
  padding: 8px 4px;
  min-height: 40px;
  resize: none;
  word-wrap: break-word;
}

.edit-subtask-input {
  margin-bottom: 4px;
}

.subtask-nonedit {
  cursor: pointer;
}

.subtask-name {
  width: 100%;
}

.subtask-name.subtask-nonedit.incomplete {
  transition: all 0.25s ease;
}

.subtask-name.subtask-nonedit.complete {
  transition: all 0.25s ease;
  opacity: 0.4;
}

.task-details-content-right {
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
  padding: 0 0 0 30px;
}

.task-details-notes {
  border: none;
  color: #000000;
  font-family: 'soleil-regular';
  width: 100%;
}

.task-details-notes:focus {
  outline: none;
}

.task-details-notes::placeholder {
  font-family: 'soleil-book';
}

.task-details-notes .s-textarea-input {
  min-height: 300px;
}

/**
 * Meta Column
 */

.task-meta-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 40px 0;
  font-size: 16px;
  user-select: none;
}

.s-label .label-text {
  font-family: 'soleil-book';
  margin-top: 4px;
}

.s-label .icon {
  opacity: 0.35;
  filter: brightness(0%);
}

.icon-winston {
  margin-right: 10px;
  height: 24px;
}

.icon-calendar {
  margin-right: 8px;
  height: 16px;
}

.icon-options {
  margin: 3px 8px 0 0;
  height: 14px;
}

.task-view-container {
  display: flex;
}

.task-view-container > div:first-child {
  margin-right: 8px;
}

.task-view-container-header {
  cursor: pointer;
}

.winston-popover {
  cursor: pointer;
  max-width: 200px !important;
}

.winston-popover {
  animation: fadeInAnimation ease 3s;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.subtask-btn-wrapper {
  margin-top: 6px;
}

.add-subtask-btn {
  background-color: #E6675B;
  border: none;
  border-radius: 6px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'soleil-semibold';
  font-size: 14px;
  padding: 8px 12px;
}

.cancel-btn {
  background-color: #CACBCD;
  border: none;
  border-radius: 6px;
  color: #292A2A;
  cursor: pointer;
  font-family: 'soleil-semibold';
  font-size: 14px;
  padding: 8px 12px;
  margin-right: 4px;
}

.subtask-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  transition: all 0.25s ease;
  width: 100%;
  margin: 4px 0;
}

.subtask-checkbox {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 8px 0 0;
}

.subtask-checkbox label:before {
  transform: scale(0.6);
}
.subtask-checkbox label:after {
  transform: scale(0.6);
}

.subtask-checkbox:hover .box:before,
.subtask-checkbox:hover label:before {
  border-color: rgba(0,0,0,0.5)!important;
  transform: scale(0.74) !important;
}

.winston-popover__to-pursue {
  color: #E6675B;
  margin-top: 10px;
  font-size: 12px;
}