.modal-dimmer {
  background: rgba(255,255,255,1);
}

.authentication-wrapper {
  align-items: center;
  animation-duration: 0.5s;
  animation-name: fadeIn;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
}

.auth-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 30px 5px;
  transition: all 0.2s ease;
  transform: scale(1);
}

.auth-logo:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.auth-logo:active {
  cursor: pointer;
  transform: scale(0.95);
}

.auth-logo img {
  width: 140px;
}

.auth-header {
  font-size: 18px;
  font-family: 'soleil-book';
  height: 21px;
}

.auth-header-active {
  color: rgba(0,0,0,1);
}

.auth-header-inactive {
  color: rgba(0,0,0,0.3);
  transition: all 0.25s ease;
}

.auth-header-inactive:hover {
  cursor: pointer;
  color: rgba(0,0,0,1);
}

.auth-header-divider {
  margin: 0 20px;
  color: rgba(0,0,0,0.3);
}

.auth-form .s-btn {
  margin: 0 auto;
}

.auth-button-wrapper {
  display: flex;
  height: 60px;
  justify-content: center;
}

.auth-button {
  background-color: #E6675B;
  border: none;
  border-radius: 6px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'soleil-regular';
  font-size: 18px;
  height: 60px;
  width: 287px;
}

i.google.icon.large {
  margin-right: 20px;
  position: relative;
  top: -3px;
}

.auth-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 20px 0;
}

.auth-field .s-label {
  justify-content: center;
  font-size: 16px;
}

.auth-field .s-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  text-align: center;
  font-size: 20px;
}

.auth-action {
  display: flex;
  width: 100%;
  margin: 40px 0 0 0;
}

.auth-notice {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'soleil-light';
  padding: 30px 0;
}

.legal-container {
  margin-top: 25px;
}

.fadeOut {
  animation: fadeOutAnimation 1s;
  /* display: none; */
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}