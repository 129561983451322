.tasks-index-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 45%;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.winston-full {
  width: 55%;
}

/**
 * Inputs
 */

.tasks-inputs-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 8% 4px 8%;
  border-right: 3px solid #F6F6F6;
  /* border-bottom: 3px solid #F6F6F6; */
  /* box-shadow: 0 3px 8px 0 rgba(0,0,0,0.05); */
}

.create-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'soleil-semibold';
  width: 100%;
  border: none;
  color: #000000;
  font-size: 24px;
  margin: 32px 0;
}

.create-input:focus {
  outline: none;
}

.create-input::placeholder {
  color: #A5A5A5;
  font-family: 'soleil-book';
}

/**
 * List
 */

.tasks-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 30px 8% 20px 8%;
}

/* width */
.tasks-list-container::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.tasks-list-container::-webkit-scrollbar-track {
  background: #F6F6F6;
}

/* Handle */
.tasks-list-container::-webkit-scrollbar-thumb {
  background: #DDD;
  transition: all 1s ease;
}

/* Handle on hover */
.tasks-list-container::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.tasks-group-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  margin: 0 0 40px 0;
}

.tasks-group-name {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  font-family: 'soleil-book';
	color: #A5A5A5;
  font-size: 16px;
  white-space: nowrap;
	margin: 0 0px 10px 0;
  user-select: none;
}

.tasks-group-name-divider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto 0;
  height: 3px;
  width: 100%;
  background: #F6F6F6;
  margin-left: 20px;
}

.task-item {
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  width: 100%;
  padding: 10px 0 10px 20px;
  transition: all 0.25s ease;
}

.task-item.complete {
  opacity: 0.4;
}

.task-item:hover {
  cursor: pointer;
  background: #F6F6F6;
  opacity: 1;
}

.task-checkbox {
  height: 100%;
  align-items: center;
  display: flex;
  margin: 0 20px 0 0;
}

.task-name {
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-size: 17px;
  line-height: 28px;
  font-family: 'soleil-book';
  padding: 6px 0;
  user-select: none;
}

.highlighted-task {
  background: #E0E0E0;
}

.task-item-meta::after {
	background: rgba(0, 0, 0, 0.15);
	content: "";
	flex-grow: 1;
	font-size: 0px;
	height: 1px;
	line-height: 0px;
	margin: 0px 8px;
}
