#w-large {
  position: relative;
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  padding: 40px 10px 10px 10px;
}

#w-large #w-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}

#w-large #w-dialogue {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: auto;
  width: auto;
  min-width: 250px;
}

#w-large #w-dialogue-bubble {
  height: auto;
  width: auto;
  min-width: 250px;
  max-width: 860px;
  margin: 0 auto;
  padding: 1.8em 2.5em 1.5em 2.5em;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 2.5px 0px 0 rgba(0,0,0,0.06);
  text-align: center;
  font-family: 'Soleil', sans-serif;
  font-size: 18px;
  letter-spacing: -0.2px;
  line-height: 28px;
  word-wrap: break-word;
}

#w-large #w-dialogue-arrow {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 28px;
  margin-top: -16px;
}

#w-large #w-dialogue-arrow-path {
  fill: #FFFFFF;
  stroke: rgba(0,0,0,0.2);
}

#w-large #w-dialogue b {
  color: #000000;
  font-family: 'soleil-book';
  text-decoration: none;
  transition: all 0.3s ease;
}

.fadeIn {
  animation: fadeInAnimation ease 1.5s;
}

.fadeOut {
  animation: fadeOutAnimation 1.5s;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}